const contents = `rRipple is still in early testing with a select group of users - if you find bugs you think should be fixed, feel free to e-mail them to <a href="mailto:improvements@rRippled.com">improvements@rRippled.com</a>`;
const wrapper = 'footer';
const id = 'early-testing-notice'
const _class = 'early-testing-notice text-center m-0 p-2';
const css = {
    'font-size': '10pt',
};

$(function () {
    $(`<p class="${_class}" id="${id}"}>${contents}</p>`).wrap(wrapper).insertAfter('main:last')
    $(`#${id}`).css(css);
});